import { Content, Modal } from '@xxxlgroup/hydra-ui-components';
import React, { FC, MouseEvent } from 'react';
import useMessage from 'components/Message/useMessage';

interface EcoTaxModalProps {
  /** on click callback */
  onClose: (event: MouseEvent | KeyboardEvent) => void;
  /** formatted price to be shown in the modal body message */
  price?: string;
}
const EcoTaxModal: FC<EcoTaxModalProps> = ({ onClose, price }) => {
  const [modalHeading, modalBody] = useMessage(['ecoTax.modal.heading', 'ecoTax.modal.body'], {
    price,
  });

  return (
    <Modal onClose={onClose} heading={modalHeading} variant="sidebar">
      <Content content={modalBody} />
    </Modal>
  );
};

export default EcoTaxModal;
