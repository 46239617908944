import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Heading } from '@xxxlgroup/hydra-ui-components';
import { HeadingLevel } from '@xxxlgroup/hydra-ui-components/dist/common/components/Heading/Heading.types';
import Link from 'components/WebshopLink';
import { scrollToElement } from 'utils/common';
import { useEntryCompoundContext } from 'components/Entries/EntryCompound/EntryCompound.context';
import { splitFirstWords } from '@xxxlgroup/hydra-utils/common';
import styles from 'components/Entries/EntryCompound/components/Name/Name.scss';

type NameSize = 'small' | 'medium' | 'large';

interface NameProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  classNameLink?: string;
  /** size for the size of the name */
  size?: NameSize;
  /** Decides if the brand should be part of the name */
  includeBrand?: boolean;
  /** decide on the Heading level | a11y important */
  headingLevel?: HeadingLevel;
  /** Decides if the name is wrapped with a link */
  isLinkRemoved?: boolean;
}

const Name = ({
  className,
  classNameLink,
  size = 'small',
  includeBrand,
  isLinkRemoved = false,
  headingLevel = 2,
}: NameProps) => {
  const {
    product: { name, code, url, urlFromSeoData, brands },
    hasFreeGiftProductVoucher,
  } = useEntryCompoundContext();

  const productUrl = url || urlFromSeoData;
  const [firstWord, restWords] = splitFirstWords(name ?? '');

  const parsedName = useMemo(() => {
    const brandName = includeBrand && brands?.[0]?.name ? `${brands[0].name} ` : '';

    return (
      <Heading level={headingLevel} SEO className={styles.name}>
        <span className={styles.firstWord}>{`${brandName}${firstWord}`}</span>
        {restWords && <span> {restWords}</span>}
      </Heading>
    );
  }, [brands, headingLevel, includeBrand, firstWord, restWords]);

  const scrollToMainSection = () => {
    scrollToElement('#main');
  };

  const entryName = useMemo(() => {
    if (hasFreeGiftProductVoucher || !productUrl || isLinkRemoved) {
      return parsedName;
    }

    return (
      <Link
        className={classnames(styles.link, classNameLink)}
        data-purpose="productList.item.link.name"
        href={productUrl}
        onClick={scrollToMainSection}
        title={name}
        type="articleName"
        productId={code}
      >
        {parsedName}
      </Link>
    );
  }, [classNameLink, code, hasFreeGiftProductVoucher, isLinkRemoved, name, parsedName, productUrl]);

  return (
    <div className={classnames(styles[size], className)} data-testid="entryName">
      {entryName}
    </div>
  );
};

export default Name;
