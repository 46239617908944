import React, { FC } from 'react';
import classnames from 'classnames';
import { Image as HydraImage } from '@xxxlgroup/hydra-ui-components';
import ConfiguredProductImage from 'components/Configurator/components/ConfiguredProductImage';
import { getProductCodeAndImage } from 'utils/product/misc';
import { useEntryCompoundContext } from 'components/Entries/EntryCompound/EntryCompound.context';
import Link from 'components/WebshopLink';
import { scrollToElement } from 'utils/common';
import styles from 'components/Entries/EntryCompound/components/Image/Image.scss';

const scrollToMainSection = () => {
  scrollToElement('#main');
};

interface ImageProps {
  /** @ignore */
  className?: string;
  /** option to add another data-purpose */
  dataPurpose?: string;
  /** lazy loads the image */
  isLazyLoaded?: boolean;
  /** Decides if the image is wrapped with a link */
  isLinkRemoved?: boolean;
  /** wrapper className for whole component */
  wrapperClassName?: string;
}

const Image: FC<ImageProps> = ({
  className,
  dataPurpose,
  isLazyLoaded = false,
  isLinkRemoved = false,
  wrapperClassName,
}) => {
  const {
    product,
    product: { url, urlFromSeoData, name, configuredProduct },
    hasFreeGiftProductVoucher,
  } = useEntryCompoundContext();

  const productUrl = url || urlFromSeoData;
  const { image, productCode } = getProductCodeAndImage(product);

  const renderImage = () => {
    if (configuredProduct) {
      return <ConfiguredProductImage source={image} className={styles.productImage} />;
    }

    return (
      <HydraImage
        altText={image.altText}
        className={styles.productImage}
        data-purpose="productList.item.image"
        height={200}
        key={`${productCode}/image`}
        loading={isLazyLoaded ? 'lazy' : 'eager'}
        source={image.url || image}
        width={200}
      />
    );
  };

  if (hasFreeGiftProductVoucher || !productUrl || isLinkRemoved) {
    return (
      <div
        className={classnames(styles.entryImage, className)}
        data-purpose="productList.item.entryImage"
      >
        {renderImage()}
      </div>
    );
  }

  return (
    <div className={classnames(styles.linkImageWrapper, wrapperClassName)}>
      {renderImage()}
      <Link
        href={productUrl}
        title={name}
        data-purpose={dataPurpose ?? 'product.backToTop.image'}
        onClick={scrollToMainSection}
        className={classnames(styles.entryImageLink, className)}
        tabIndex={-1}
        ariaHidden
        type="articleImage"
        productId={productCode}
      />
    </div>
  );
};

export default Image;
