import { isObjectEmpty, isValuePresent } from '@xxxlgroup/hydra-utils/common';
import type {
  Dimensions,
  Product,
  VariantContainer,
} from 'graphql-types/generated/types-generated';
import type { ProductAttributes, ProductAttributeValue } from 'types/types';

const hasColorVariantImage = (variantContainerData: VariantContainer) => {
  const colorVariant = variantContainerData?.variantGroups?.find(
    (group) => group?.name === 'color',
  );
  return colorVariant?.variantValues?.[0]?.productPicture;
};

const getDimensionsKey = (dimensions?: Dimensions | null): string | null => {
  if (!dimensions) {
    return null;
  }

  const { strRepresentationKey } = dimensions ?? {};
  return isValuePresent(strRepresentationKey) ? `${strRepresentationKey}: ` : null;
};

const getColor = (product: Product): ProductAttributeValue | null => {
  const { attributes, color, variantContainerData } = product;
  const value =
    !variantContainerData ||
    hasColorVariantImage(variantContainerData && (variantContainerData as VariantContainer))
      ? color
      : attributes?.colorMapping?.targetColor;

  return value ? { value } : null;
};

const formatAttributes = (product: Product, disableKeys: boolean): ProductAttributes => {
  const { attributes } = product;
  const dimensionsKey = getDimensionsKey(attributes?.dimensions);
  const parsedColor = getColor(product);
  const parsedMaterial: ProductAttributeValue | null = isValuePresent(attributes?.material)
    ? { value: attributes?.material || '' }
    : null;

  const parsedDimensions: ProductAttributeValue | null = dimensionsKey
    ? {
        ...(!disableKeys && { key: dimensionsKey }),
        value: attributes?.dimensions?.strRepresentation || '',
      }
    : null;

  return [parsedColor, parsedMaterial, parsedDimensions].filter(
    (attribute) => attribute,
  ) as ProductAttributes;
};

const getProductAttributes = (product: Product, disableKeys = false): ProductAttributes => {
  const { attributes } = product;

  if (isObjectEmpty({ ...attributes })) {
    return [];
  }

  return formatAttributes(product, disableKeys);
};

export default getProductAttributes;
