import React from 'react';
import classnames from 'classnames';
import { OrderEntry, Product } from 'graphql-types/generated/types-generated';
import useEcoTaxModal from 'components/EcoTax/useEcoTaxModal';
import useEcoTaxMessages from 'components/EcoTax/useEcoTaxMessages';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import styles from 'components/Entries/EntryCompound/components/EcoTax/EcoTax.scss';

const EcoTax = ({
  additionalExpenses,
  additionalProductExpenses,
  withTotalPrice = false,
  className,
}: {
  additionalExpenses?: OrderEntry['additionalExpenses'];
  additionalProductExpenses?: Product['additionalProductExpenses'];
  withTotalPrice?: boolean;
  className?: string;
}) => {
  const expenses = additionalExpenses ?? additionalProductExpenses;
  const singlePrice = expenses?.[0]?.price;
  const value = withTotalPrice ? additionalExpenses?.[0]?.totalPrice : singlePrice;
  const { prefixEcoTax, messageTotalCartPrice } = useEcoTaxMessages(value);
  const { EcoTaxButton, EcoTaxModal, isEcoTaxModalOpen } = useEcoTaxModal(singlePrice);

  if (!Array.isArray(expenses) || isArrayEmpty(expenses)) {
    return null;
  }

  return withTotalPrice ? (
    <span className={classnames(styles.wrapper, className)}>{messageTotalCartPrice}</span>
  ) : (
    <>
      <span className={classnames(styles.wrapper, className)}>
        <span className={styles.prefix}>{prefixEcoTax}</span>
        <EcoTaxButton />
      </span>
      {isEcoTaxModalOpen && <EcoTaxModal />}
    </>
  );
};

export default EcoTax;
