import React, { forwardRef, useMemo } from 'react';
import EntryCompoundContext from 'components/Entries/EntryCompound/EntryCompound.context';
import {
  EntryCompoundProps,
  EntryCompoundContextTypes,
} from 'components/Entries/EntryCompound/EntryCompound.types';

const EntryCompound = forwardRef<HTMLElement, EntryCompoundProps>(
  (
    {
      children,
      className,
      count = 1,
      hasFreeGiftProductVoucher = false,
      product = {},
      dataPurpose,
      dataProductId,
      dataTrackId,
    },
    ref,
  ) => {
    const context: EntryCompoundContextTypes = useMemo(
      () => ({ product, count, hasFreeGiftProductVoucher }),
      [count, hasFreeGiftProductVoucher, product],
    );

    return (
      <EntryCompoundContext.Provider value={context}>
        <article
          ref={ref}
          className={className}
          data-purpose={dataPurpose}
          data-product-id={dataProductId}
          data-track-id={dataTrackId}
        >
          {children}
        </article>
      </EntryCompoundContext.Provider>
    );
  },
);

export default EntryCompound;
