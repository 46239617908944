import React from 'react';
import classnames from 'classnames';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import type { AttributesSize, ProductAttributes } from 'types/types';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';

import styles from 'components/Attributes/Attributes.scss';

export interface AttributesProps {
  /** Sets the product/article attributes for the component */
  attributes: ProductAttributes;
  /** @ignore */
  className?: string;
  /** Sets the product summary for the component */
  summary?: string | React.ReactNode;
  /** The size of the attributes */
  size?: AttributesSize;
  /** Flag for render attributes instead of summary in moebelix order information */
  isAttributePrioritized?: boolean;
}

const Attributes = ({
  attributes,
  className,
  size = 'small',
  summary,
  isAttributePrioritized = false,
}: AttributesProps) => {
  const isSummaryShown = useFeatureFlagsEnabled('poseidon.product.summary.manual');

  if (isArrayEmpty(attributes)) {
    return null;
  }

  const summaryElement = (
    <span className={classnames(styles.summary, styles[size])}>{summary}</span>
  );

  const attributesElement = attributes?.map(
    ({ key, value }) =>
      value && (
        <span
          className={classnames(styles.attribute, styles[size])}
          key={`attribute-${key}-${value}`}
        >
          {key}
          {value}
        </span>
      ),
  );

  return (
    <div
      className={classnames(styles.productAttributesWrapper, className)}
      data-purpose="product.productAttributes"
    >
      {isSummaryShown && !isAttributePrioritized && summary && summaryElement}

      {isSummaryShown && isAttributePrioritized && attributesElement}
      {!isSummaryShown && attributesElement}
    </div>
  );
};

export default Attributes;
