import useLanguage from 'hooks/useLanguage';
import { getPriceParts } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';

const useEcoTaxMessages = (price?: number | null) => {
  const { currencyFormat } = useLanguage();
  const { price: formattedPrice } = getPriceParts(price, currencyFormat);
  const messageTotalCartPrice = useMessage('ecoTax.cart.price', { price: formattedPrice }, true);
  const [prefixEcoTax, messageEcoTax, messageEcoTaxIncludedInPrice, messageEcoTaxSummary] =
    useMessage(
      [
        'ecoTax.cost.excluding',
        'ecoTax.pdp',
        'ecoTax.cost.includedInProductPrice',
        'ecoTax.cart.summary',
      ],
      {
        price: formattedPrice,
      },
    );

  return {
    prefixEcoTax,
    messageEcoTax,
    messageTotalCartPrice,
    messageEcoTaxIncludedInPrice,
    messageEcoTaxSummary,
  };
};

export default useEcoTaxMessages;
