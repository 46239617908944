import React, { useState, useCallback } from 'react';
import { getPriceParts } from '@xxxlgroup/hydra-ui-components';
import useLanguage from 'hooks/useLanguage';
import { ThemeType } from '@xxxlgroup/hydra-ui-components/dist/common/components/Link/Link.types';
import EcoTaxModal from 'components/EcoTax/components/EcoTaxModal';
import EcoTaxButton from 'components/EcoTax/components/EcoTaxButton';
import useEcoTaxMessages from 'components/EcoTax/useEcoTaxMessages';

const useEcoTaxModal = (price?: number | null, isEcoTaxIncludedInPrice?: boolean) => {
  const { currencyFormat } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { price: formattedPrice } = getPriceParts(price, currencyFormat);
  const { messageEcoTax, messageEcoTaxIncludedInPrice } = useEcoTaxMessages(price);
  const messageButton = isEcoTaxIncludedInPrice ? messageEcoTaxIncludedInPrice : messageEcoTax;

  const onToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const Modal = () => <EcoTaxModal onClose={onToggleModal} price={formattedPrice} />;

  const Button = ({
    className,
    theme = 'concrete',
    message = messageButton,
  }: {
    className?: string;
    theme?: ThemeType;
    message?: string;
  }) => (
    <EcoTaxButton onClick={onToggleModal} message={message} className={className} theme={theme} />
  );

  return {
    EcoTaxModal: Modal,
    EcoTaxButton: Button,
    isEcoTaxModalOpen: isModalOpen,
  };
};

export default useEcoTaxModal;
