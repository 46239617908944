import React, { FC, MouseEvent } from 'react';
import { ThemeType } from '@xxxlgroup/hydra-ui-components/dist/common/components/Link/Link.types';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';

interface EcoTaxButtonProps {
  /** @ignore */
  className?: string;
  /** button theme */
  theme?: ThemeType;
  /** on click callback */
  onClick: (event: MouseEvent | KeyboardEvent) => void;
  /** the message to be shown inside of the button */
  message: string;
}
const EcoTaxButton: FC<EcoTaxButtonProps> = ({ className, theme, onClick, message }) => (
  <HydraLink
    typography="small"
    theme={theme}
    onClick={onClick}
    data-purpose="ecoTax.modal.open"
    className={className}
  >
    {message}
  </HydraLink>
);

export default EcoTaxButton;
