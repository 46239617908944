import React from 'react';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import getProductAttributes from 'utils/product/attributes/getProductAttributes';
import { useEntryCompoundContext } from 'components/Entries/EntryCompound/EntryCompound.context';
import AttributesCommon from 'components/Attributes';
import type { AttributesSize } from 'types/types';

interface AttributesProps {
  /** @ignore */
  className?: string;
  /** uses just the values of the attributes without the keys */
  withDisabledKeys?: boolean;
  /** The size of the attributes */
  size?: AttributesSize;
}

const Attributes = ({ className, withDisabledKeys = false, size = 'xSmall' }: AttributesProps) => {
  const { product } = useEntryCompoundContext();
  const attributesData = getProductAttributes(product, withDisabledKeys);
  const hasAttributes = !isArrayEmpty(attributesData);

  if (!hasAttributes) {
    return null;
  }

  const attributes = attributesData.slice(0, 3);

  return <AttributesCommon attributes={attributes} className={className} size={size} />;
};

export default Attributes;
