import React, { FC } from 'react';
import { Image } from 'utils/product/types';

interface ConfiguredProductImageProps {
  /** @ignore */
  className?: string;
  /** id of configurable product */
  source?: Omit<Image, 'hashCode'>;
}

// Since the Hydra Image Component can't handle that type of source an own component is necessary
const ConfiguredProductImage: FC<ConfiguredProductImageProps> = (props) => {
  const { className, source } = props;

  if (!source?.url) {
    return null;
  }

  return <img alt={source.altText} className={className} src={source.url} />;
};

export default ConfiguredProductImage;
