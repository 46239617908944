import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { useEntryCompoundContext } from 'components/Entries/EntryCompound/EntryCompound.context';
import { PriceCompound, PriceCurrent, PriceOld } from '@xxxlgroup/hydra-ui-components';
import useLanguage from 'hooks/useLanguage';
import { getCurrentPriceUnitProp } from 'utils/product/price/unit';
import {
  checkIfOldPriceIsStrike,
  generateOldPriceAccessibilityProps,
  useCheckIfOldPriceIsShown,
  useOldPriceMessage,
  getAsteriskCount,
} from 'utils/product/price/oldPrice';
import { PriceSizes } from '@xxxlgroup/hydra-ui-components/dist/common/molecules/PriceCompound/PriceCompound.types';
import styles from 'components/Entries/EntryCompound/components/Price/Price.scss';

interface PriceProps {
  /** aligns the price elements */
  alignment?: 'left' | 'right';
  /** @ignore */
  className?: string;
  /** SearchCollector checkout tracking attribute that identifies the component type */
  dataTrackId?: 'priceContainer';
  /** displays a bigger current price */
  withLargeCurrentPrice?: boolean;
  /** the calculated total price */
  priceCurrentTotal?: number | null;
  /** the calculated total old price */
  priceOldTotal?: number | null;
}

const Price: FC<PriceProps> = ({
  alignment = 'left',
  className,
  dataTrackId,
  priceCurrentTotal,
  priceOldTotal,
  withLargeCurrentPrice = false,
}) => {
  const { currencyFormat } = useLanguage();
  const { product } = useEntryCompoundContext();

  const { priceData, code: productId } = product;
  const { currentPrice, oldPrice } = priceData || {};
  const isTotalCurrentPriceZero = priceCurrentTotal === 0; // needed for reserved products, if they are no more reservable
  const showTotalPrice = priceCurrentTotal || isTotalCurrentPriceZero;
  const currentPriceValue = showTotalPrice ? priceCurrentTotal : currentPrice?.value;
  const oldPriceValue = showTotalPrice ? priceOldTotal : oldPrice?.value;
  const currentPriceId = useMemo(() => `entryPriceCurrent-${productId}`, [productId]);
  const asteriskCount = getAsteriskCount(currentPrice);
  const showOldPrice = useCheckIfOldPriceIsShown(
    oldPriceValue,
    currentPriceValue === oldPriceValue,
    priceData?.hideUvp,
  );
  const oldPriceMessage = useOldPriceMessage(asteriskCount, priceOldTotal || oldPrice);
  const isOldPriceStrike = checkIfOldPriceIsStrike(asteriskCount);

  if (!currentPriceValue && !isTotalCurrentPriceZero) {
    return null;
  }

  return (
    <PriceCompound
      currencyFormat={currencyFormat}
      data-testid="entryPrice"
      className={classnames(styles.wrapper, className, styles[`align-${alignment}`])}
      size={PriceSizes.SMALL}
    >
      {showOldPrice && (
        <PriceOld
          {...generateOldPriceAccessibilityProps(currentPriceId)}
          asteriskCount={asteriskCount}
          className={classnames({ [styles.priceOld]: withLargeCurrentPrice })}
          data-purpose="entryPrice.oldPrice"
          value={oldPriceMessage}
          strikeThrough={isOldPriceStrike}
        />
      )}
      {currentPriceValue && (
        <PriceCurrent
          className={classnames({ [styles.priceCurrentLarge]: withLargeCurrentPrice })}
          id={currentPriceId}
          data-purpose="entryPrice.currentPrice"
          data-track-id={dataTrackId}
          discounted={!!oldPriceValue}
          unit={!priceCurrentTotal ? getCurrentPriceUnitProp(priceData) : undefined}
          value={currentPriceValue}
          size={PriceSizes.SMALL}
        />
      )}
    </PriceCompound>
  );
};

export default Price;
